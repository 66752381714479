<template>
  <div class="home" style="height: auto">
    <el-container style="height: 100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="width: 100%; padding: 0; margin: auto">
        <el-carousel
          :interval="5000"
          arrow="always"
          height="28rem"
          style="width: 100%"
          ref="banners"
        >
          <el-carousel-item
            class="banner1"
            style=" align-items: center; color: #eeeeee; ); background-repeat: repeat; background-size: cover;"
          >
            <div
              style="
                margin: 0 auto;
                align-items: center;
                width: 100%;
                color: white;
                padding-top: 50px;
              "
              ref="banner1text"
            >
              <h1 style="font-weight: bolder; font-size: 300%" v-if="isMobile">TextualESG</h1>
              <h1 style="font-weight: bolder; font-size: 400%" v-if="!isMobile">TextualESG</h1>
              <h2>基于多源文本信息的<br>ESG评估平台</h2>

            </div>
          </el-carousel-item>
          <el-carousel-item
            class="banner2"
            style="display: flex;align-items: center; color: #eeeeee; ); background-repeat: repeat; background-size: cover;"
          >
            <div
              style="
                margin: 0 auto;
                text-align: left;
                width: 100%;
                color: white;
                display: flex;
              "
              ref="banner2text"
            >
              <div style="margin: 3%; ">
                <h1
                  style="font-family: 'STZhongsong',serif; font-size: 400%; font-weight: bolder; margin-bottom: 0;"
                  v-if="!isMobile"
                >
                  基于多源文本信息的<br>ESG评价指标</h1>
                <h1
                  style="font-family: 'STZhongsong',serif; font-size: 233%; font-weight: bolder; margin-top: 3rem; margin-left: 2rem"
                  v-if="isMobile"
                >
                  基于多源<br>文本信息的<br>ESG评价指标</h1>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item
            class="banner3"
            style="display: flex;align-items: center; color: #eeeeee; ); background-repeat: repeat; background-size: cover;"
          >
            <div
              style="
                margin: 0 auto;
                text-align: left;
                width: 100%;
                color: white;
                display: flex;
              "
              ref="banner3text"
            >
              <div style="margin: 3%">
                <h1 style="font-family: 'STZhongsong',serif; font-weight: bolder; margin-bottom: 0; font-size: 400%"
                    v-if="!isMobile"
                >
                  客观全面的<br>ESG评级系统</h1>
                <h1
                  style="font-family: 'STZhongsong',serif; font-weight: bolder; margin-top: 5rem; font-size: 233%; margin-left: 2rem"
                  v-if="isMobile"
                >
                  客观全面的<br>ESG评级系统</h1>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item
            class="banner4"
            style=" display: flex; align-items: center; color: #eeeeee; ); background-repeat: repeat; background-size: cover;"
          >
            <div
              style="
                margin: 0 auto;
                text-align: left;
                width: 100%;
                color: white;
                display: flex;
              "
              ref="banner4text"
            >
              <div style="margin: 3%">
                <h1 style="font-family: 'STZhongsong',serif; font-weight: bolder; margin-bottom: 0; font-size: 400%"
                    v-if="!isMobile">
                  个性化的<br>ESG投资组合推荐和管理
                </h1>
                <h1
                  style="font-family: 'STZhongsong',serif; font-weight: bolder; margin-top: 3rem; font-size: 233%; margin-left: 2rem"
                  v-if="isMobile">
                  个性化的<br>ESG投资组合<br>推荐和管理
                </h1>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <br/>
        <div style="width: 85%; margin: 0 auto">
          <h1 style="font-family: 'Times New Roman',serif">
            TextualESG：基于多源文本信息的&nbsp;ESG&nbsp;评估平台
          </h1>
          <h3 style="width: 90%; text-align: center; margin: 0 auto">
            本项目为2021-2022年第十七届 “花旗杯”金融创新应用大赛参赛作品
          </h3>
          <p style="font-size: 1.2rem; text-align: left; line-height: 150%">
            &emsp;&emsp;TextualESG平台利用自然语言处理技术从上市公司社会责任报告、上市公司实地调研问答文本、上市公司新闻、分析师报告这四类文本中提取上市公司的ESG信息，
            并参考前沿学术文献中的方法，将文本ESG信息转化为关注度指标、情感指标等，最后计算得到我们的文本ESG评分，做到通过提取多源文本信息来弥补现存ESG评分体系的不足。
            除了提供ESG文本数据和ESG文本指标以外，TexualESG平台还为具有不同投资理念的ESG投资者定制了个性化的投资策略，以及投资组合管理功能。
          </p>
          <el-row
            :gutter="0"
            style="width: 100%; margin: 0 auto; text-align: center"
          >
            <el-col
              v-for="(feature, index) in systemFeature"
              :key="index"
              :span="colWidth"
            >
              <div class="grid-content">
                <div style="padding: 30px">
                  <font-awesome-icon :icon="feature.icon" size="4x"/>
                  <h2>{{ feature.title }}</h2>
                  <p>{{ feature.text }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div style="margin-bottom: 30px; background: white">
          <br/>
          <h1>多源文本</h1>
          <div v-show="!isMobile" style="width: 90%; margin: 0 auto">
            <el-row>
              <el-col :span="6" style="background: #F56C6C36">
                <div>
                  <h3 style="float: left; margin-left: 5%">新闻资讯</h3>
                  <router-link
                    style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                    to="/rank/indexbrowser"
                  >
                    查看更多
                    <i class="el-icon-d-arrow-right"></i>
                  </router-link>
                  <img style="width:90%;height: 300px;"
                       src="https://static.textualesg.com/news.jpg"/>
                  <el-table
                    :data="newsTable"
                    :show-header="status"
                    style="width: 90%; float: left; margin-left: 5%; margin-bottom: 1rem"
                    @cell-click="getTextDetail"
                  >
                    <el-table-column label="标题" prop="title" show-overflow-tooltip>
                      <template v-slot="scope">
                        <el-link>{{ scope.row.title }}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="date" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="6" style="background: #409EFF36">
                <div>
                  <h3 style="float: left; margin-left: 5%; object-fit:cover">CSR报告</h3>
                  <router-link
                    style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                    to="/rank/indexbrowser"
                  >
                    查看更多
                    <i class="el-icon-d-arrow-right"></i>
                  </router-link>
                  <img style="width:90%;height: 300px; object-fit:cover"
                       src="https://static.textualesg.com/csr.png">
                  <el-table
                    :data="csrTable"
                    :show-header="status"
                    style="width: 90%; float: left; margin-left: 5%; margin-bottom: 1rem"
                    @cell-click="getTextDetail"
                  >
                    <el-table-column label="标题" prop="title" show-overflow-tooltip>
                      <template v-slot="scope">
                        <el-link>{{ scope.row.title }}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="date" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="6" style="background: #E6A23C36">
                <div>
                  <h3 style="float: left; margin-left: 5%">分析师报告</h3>
                  <router-link
                    style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                    to="/rank/indexbrowser"
                  >
                    查看更多
                    <i class="el-icon-d-arrow-right"></i>
                  </router-link>
                  <img style="width: 90%;height: 300px; object-fit:cover"
                       src="https://static.textualesg.com/ana.jpg">
                  <el-table
                    :data="anaTable"
                    :show-header="status"
                    style="width: 90%; float: left; margin-left: 5%;  margin-bottom: 1rem"
                    @cell-click="getTextDetail"
                  >
                    <el-table-column label="标题" prop="title" show-overflow-tooltip>
                      <template v-slot="scope">
                        <el-link>{{ scope.row.title }}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="date" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="6" style="background: #67C23A36">
                <div>
                  <h3 style="float: left; margin-left: 5%">调研问答</h3>
                  <router-link
                    style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                    to="/rank/indexbrowser"
                  >
                    查看更多
                    <i class="el-icon-d-arrow-right"></i>
                  </router-link>
                  <img style="width:90%; height: 300px; object-fit:cover"
                       src="https://static.textualesg.com/qa.jpg">
                  <el-table
                    :data="qaTable"
                    :show-header="status"
                    style="width: 90%; float: left; margin-left: 5%; margin-bottom: 1rem"
                    @cell-click="getTextDetail"
                  >
                    <el-table-column label="标题" prop="title" show-overflow-tooltip>
                      <template v-slot="scope">
                        <el-link>{{ scope.row.title }}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="date" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-show="isMobile" style="width: 90%; margin: 0 auto">
            <el-row>
              <el-col>
                <div>
                  <h3 style="float: left; margin-left: 5%">新闻资讯</h3>
                  <router-link
                    style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                    to="/rank/indexbrowser"
                  >
                    查看更多
                    <i class="el-icon-d-arrow-right"></i>
                  </router-link>
                  <img style="width:90%;height: 300px; object-fit:cover"
                       src="https://static.textualesg.com/news.jpg"/>
                  <el-table
                    :data="newsTable"
                    :show-header="status"
                    style="width: 90%; float: left; margin-left: 5%"
                    @cell-click="getTextDetail"
                  >
                    <el-table-column label="标题" prop="title" show-overflow-tooltip>
                      <template v-slot="scope">
                        <el-link>{{ scope.row.title }}</el-link>
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="date" width="100">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <h3 style="float: left; margin-left: 20px">CSR报告</h3>
                <router-link
                  style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                  to="/rank/indexbrowser"
                >
                  查看更多
                  <i class="el-icon-d-arrow-right"></i>
                </router-link>
                <img style="width:90%;height: 300px; object-fit:cover"
                     src="https://static.textualesg.com/csr.png"/>
                <el-table
                  :data="csrTable"
                  :show-header="status"
                  style="width: 90%; float: left; margin-left: 5%"
                  @cell-click="getTextDetail"
                >
                  <el-table-column label="标题" prop="title" show-overflow-tooltip>
                    <template v-slot="scope">
                      <el-link>{{ scope.row.title }}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column label="时间" prop="date" width="100">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <h3 style="float: left; margin-left: 20px">分析师报告</h3>
                <router-link
                  style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                  to="/rank/indexbrowser"
                >
                  查看更多
                  <i class="el-icon-d-arrow-right"></i>
                </router-link>
                <img style="width: 90%;height: 300px; object-fit:cover"
                     src="https://static.textualesg.com/ana.jpg">
                <el-table
                  :data="anaTable"
                  :show-header="status"
                  style="width: 90%; float: left; margin-left: 5%"
                  @cell-click="getTextDetail"
                >
                  <el-table-column label="标题" prop="title" show-overflow-tooltip>
                    <template v-slot="scope">
                      <el-link>{{ scope.row.title }}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column label="时间" prop="date" width="100">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <h3 style="float: left; margin-left: 20px">调研问答</h3>
                <router-link
                  style="
                  float: right;
                  margin-top: 27px;
                  margin-right: 50px;
                  font-size: 1.1rem;
                "
                  to="/rank/indexbrowser"
                >
                  查看更多
                  <i class="el-icon-d-arrow-right"></i>
                </router-link>
                <img style="width:90%; height: 300px; object-fit:cover"
                     src="https://static.textualesg.com/qa.jpg">
                <el-table
                  :data="qaTable"
                  :show-header="status"
                  style="width: 90%; float: left; margin-left: 5%"
                  @cell-click="getTextDetail"
                >
                  <el-table-column label="标题" prop="title" show-overflow-tooltip>
                    <template v-slot="scope">
                      <el-link>{{ scope.row.title }}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column label="时间" prop="date" width="100">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
          <br/>
        </div>

        <div style="width: 100%">
          <br/>
          <h1 style="margin: 0 auto">系统流程</h1>

          <el-steps
            ref="steps"
            :active="7"
            align-center
            style="width: 90%; margin: 0 auto; padding: 2rem; font-size: 1rem"
          >
            <el-step
              v-for="(step, key) in systemSteps"
              :key="key"
              :description="step.description"
              :title="step.title"
              style="cursor: pointer"
              @click.native="handleStepClick(step)"
            ></el-step>
          </el-steps>

          <h2>现在，从
            <el-button size="large" type="primary" style="margin: 1rem; font-size: 1.5rem" @click="goToLogin">登录/注册
            </el-button>
            开始吧！
          </h2>
        </div>

        <!--        <h1>核心算法</h1>-->
        <!--        <el-row-->
        <!--          :gutter="0"-->
        <!--          style="width: 90%; margin: 0 auto; text-align: center"-->
        <!--        >-->
        <!--          <el-col-->
        <!--            :span="colWidth"-->
        <!--            v-for="(algorithm, index) in algorithms"-->
        <!--            :key="index"-->
        <!--          >-->
        <!--            <div class="grid-content">-->
        <!--              <div style="padding: 30px">-->
        <!--                <font-awesome-icon :icon="algorithm.icon" size="4x" />-->
        <!--                <h2>{{ algorithm.title }}</h2>-->
        <!--                <p>{{ algorithm.text }}</p>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-col>-->
        <!--        </el-row>-->

        <!--        <div style="margin: 0 auto; background-color: #ffffff">-->
        <!--          <h1 style="padding: 1rem; margin: 0 auto">团队介绍</h1>-->
        <!--        <AboutUs></AboutUs>-->
        <!--        </div>-->
      </el-main>
    </el-container>
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'
import AboutUs from '../../components/AboutUs'
import {getRecentText} from '../../api/public'

export default {
  name: 'home',
  components: {
    AboutUs,
    FooterLayout,
    HeaderLayout
  },
  data() {
    return {
      titleSize: '400%',
      activeNames: ['0'],
      systemSteps: [
        {
          title: '用户注册登录',
          description: '',
          url: '/login'
        },
        {
          title: '查询ESG评级',
          description: '',
          url: '/rank/indexbrowser'
        },
        {
          title: '查看ESG指数',
          description: '',
          url: '/esgindex'
        },
        {
          title: '创建投资组合',
          description: '',
          url: '/invest'
        },
        {
          title: '投资组合管理',
          description: '',
          url: '/combination'
        },
      ],
      systemFeature: [
        {
          icon: 'fa-solid fa-filter-circle-dollar',
          title: '文本信息挖掘',
          text: '以多源文本为核心，高效整合来源广泛的碎片化信息，深度挖掘文本隐含的企业综合ESG绩效表现，从ESG本源发现企业长期价值。'
        },
        {
          icon: 'fa-solid fa-shield',
          title: 'ESG评分',
          text: '基于多源非结构化信息搭建ESG评分体系，运用多元的信息收集手段和先进的数据处理的方法，打造客观、中立且准确的评价准则。'
        },
        {
          icon: 'fa-solid fa-bell-concierge',
          title: '定制化服务',
          text: '帮助客户依据自身偏好获取定制化用户体验，结合用户自身行业判断与板块偏好，基于用户自主设置的指标提供定制化推荐，并反馈回测结果。'
        }
      ],
      advantages: [
        {
          icon: 'fa-solid fa-database',
          title: '更加广泛的数据来源',
          text: '不同于传统的ESG评价系统，我们的数据包括结构化、半结构化和非结构化的数据，特别是将多源文本包含在其中，因此具有独特的优势。'
        },
        {
          icon: 'fa-solid fa-calculator',
          title: '更加科学的算法设置',
          text: '我们利用LDA主题模型分类，并且根据文献以及训练结果反复验证得出最佳的配比，和基于随机的选择参的计算比具有相当大的优势。'
        },
        {
          icon: 'fa-solid fa-hands-clapping',
          title: '更加广阔的应用前景',
          text: '我们可将结果拆解成更多维度的实用信息，从而为不同主体带来更多参考价值。投资者就可以更绿色的投资，而政府也可以更好地监督。'
        }
      ],
      algorithms: [
        {
          icon: 'fa-solid fa-book',
          title: '用于生成文档主题的概率模型（LDA)',
          text: '通过词、主题和文档三层结构之间的多项式分布，基于公司新闻文本和问答文本筛选ESG相关信息。'
        },
        {
          icon: 'fa-solid fa-spell-check',
          title: '种子词典法',
          text: '利用word2vec模型，构建ESG正负面词典，并应用TF-IDF方法，可以有靶向性地刻画公司公开文档中的ESG正负面表现。'
        },
        {
          icon: 'fa-solid fa-diagram-project',
          title: '神经网络',
          text: '基于ESG投资指标，通过神经网络构建ESG投资模型，控制尾部风险，挖掘ESG信息中的Alpha。'
        }
      ],
      isMobile: false,
      colWidth: 8,
      carouselHeight: '400px',
      status: false,
      anaTable: [],
      csrTable: [],
      newsTable: [],
      qaTable: [],
      newsColor: "#F56C6C36",
      qaColor: "#67C23A36",
      csrColor: "#409EFF36",
      anaColor: "#E6A23C36"
    }
  },
  methods: {
    goToAbout() {
      window.location.replace('/about')
    },
    goToLogin() {
      window.location.replace('/login')
    },
    handleStepClick(step) {
      if (step.title.includes('ESG指数')) {
        this.$router.push(step.url)
      } else if (this.$store.state.isLogin) {
        this.$router.push(step.url)
      } else {
        this.$router.push('/login')
      }
    },
    handleChange(val) {
      // console.log(val);
    },
    calcSize(that) {
      if (window.innerWidth < 1200) {
        that.isMobile = true
        // that.$refs.banners.height='20rem'
      } else {
        that.isMobile = false
        // that.$refs.banners.height='28rem'
      }
      if (window.innerWidth < 800) {
        that.colWidth = 24
        that.carouselHeight = '550px'
        that.$refs.steps.direction = 'vertical'
      } else {

        that.colWidth = 8
        that.carouselHeight = '420px'
        that.$refs.steps.direction = 'horizontal'
      }
    },
    getTextDetail(row, column, cell, event) {
      // 跳转至对应文本详情页
      if (column.label === '标题') {
        this.$router.push({
          path: '/esgtexthome',
          query: {
            text_id: row.text_id,
            issuer: row.issuer
          }
        })
      }
    },
    fillText() {
      getRecentText().then((res) => {
        // console.log(res)
        const ana = res.data.ana
        // console.log(ana)
        let cnt = 0
        for (var item in ana) {
          // console.log(item)
          const text_id = ana[item].id
          const title = ana[item].title
          const date = ana[item].date
          const issuer = ana[item].issuer
          this.anaTable.push({
            title: title,
            date: date,
            issuer: issuer,
            text_id: text_id
          })
          cnt++
          if (cnt == 3) break
        }

        const csr = res.data.csr
        // console.log(ana)
        cnt = 0
        for (var item in csr) {
          // console.log(item)
          const text_id = csr[item].id
          const title = csr[item].title
          const date = csr[item].date
          const issuer = csr[item].issuer
          this.csrTable.push({
            title: title,
            date: date,
            issuer: issuer,
            text_id: text_id
          })
          cnt++
          if (cnt == 3) break
        }

        const news = res.data.news
        // console.log(ana)
        cnt = 0
        for (var item in news) {
          // console.log(item)
          const text_id = news[item].id
          const title = news[item].title
          const date = news[item].date
          const issuer = news[item].issuer
          this.newsTable.push({
            title: title,
            date: date,
            issuer: issuer,
            text_id: text_id
          })
          cnt++
          if (cnt == 3) break
        }

        const qa = res.data.qa
        // console.log(ana)
        cnt = 0
        for (var item in qa) {
          // console.log(item)
          const text_id = qa[item].id
          const title = qa[item].title
          const date = qa[item].date
          const issuer = qa[item].issuer
          this.qaTable.push({
            title: title,
            date: date,
            issuer: issuer,
            text_id: text_id
          })
          cnt++
          if (cnt == 3) break
        }
      })
    }
  },
  mounted() {
    const that = this
    this.calcSize(this)
    window.onresize = () => {
      return (() => {
        that.calcSize(that)
      })()
    }

    this.fillText()
  }
}
</script>

<style>
.el-header {
  background-color: #34495e;
  color: #333;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
}

.el-collapse-item.el-collapse {
  background-color: #dfe2e5;
}

.banner1 {
  background-image: url("https://static.textualesg.com/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%871%E6%97%A0%E5%AD%97.png");
  background-size: 233%;
  animation: bg_anim 40s ease infinite;
  -webkit-animation: bg_anim 40s ease infinite;
  -moz-animation: bg_anim 40s ease infinite;
}

.banner2 {
  background-image: url("https://static.textualesg.com/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%872%E6%97%A0%E5%AD%97.png");
  background-size: 233%;
  animation: bg_anim 40s ease infinite;
  -webkit-animation: bg_anim 40s ease infinite;
  -moz-animation: bg_anim 40s ease infinite;
}

.banner3 {
  background-image: url("https://static.textualesg.com/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%873%E6%97%A0%E5%AD%97.png");
  background-size: 233%;
  animation: bg_anim 40s ease infinite;
  -webkit-animation: bg_anim 40s ease infinite;
  -moz-animation: bg_anim 40s ease infinite;
}

.banner4 {
  background-image: url("https://static.textualesg.com/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%874%E6%97%A0%E5%AD%97.png");
  background-size: 233%;
  animation: bg_anim 40s ease infinite;
  -webkit-animation: bg_anim 40s ease infinite;
  -moz-animation: bg_anim 40s ease infinite;
}

@keyframes bg_anim {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 20% 0%;
  }
  50% {
    background-position: 10% 10%;
  }
  75% {
    background-position: 20% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}

</style>
